import React from 'react';
import { RingLoader } from 'react-spinners';

const Loader = () => {
    return (
        <>
            <div className='loader_div'>
                <RingLoader color="#fff"  size={100}/>
            </div>
        </>
    )
}

export default Loader;
