import { apiCall } from "../../api";
import { baseUrl } from "../../constants/url";
import { store } from "../store";

export const getFetchApi = ({ url, method = 'POST', data, hasFileUpload = false, apiCallBack }) => async (dispatch) => {

    // const accessTok = store.getState()?.auth?.authData?.tokens?.access?.token
    // const csrfToken = store.getState()?.auth?.authData?.tokens?.['x-csrf-token']

    let config = {
        method: method,
        url: `${baseUrl}${url}`,
        headers: {
            // Authorization: `Bearer ${accessTok}`,
            "Content-Type": hasFileUpload ? "multipart/form-data" : "application/json",
            // 'x-csrf-token': csrfToken
        }
    };
    if (data) {
        config.data = data
    }

    let response = await apiCall(config, dispatch);

    try {
        if (response?.status == 200) {
            apiCallBack(response);
        } else {
            apiCallBack(response)
        }
    } catch (error) {
        apiCallBack(error);
    }
}
