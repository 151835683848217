import { useState } from 'react';
import { Drawer } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import { HiMenuAlt3 } from "react-icons/hi";
import { useSelector } from 'react-redux';
import { fileUrl } from '../constants/url';
import logo from '../assets/logo.png';

const activeTab = {
    fontWeight: 800
}
const Header = () => {
    const [open, setOpen] = useState(false);
    const homeData = useSelector(state => state?.common?.homeData);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <header className='header_div'>
                <div className='header_container'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-5 col-sm-4 col-md-3 col-lg-2'>
                            <Link to='/'><img src={`${fileUrl}${homeData?.logo ? homeData?.logo : logo}`} alt='logo_image' className='header_logo' /></Link>
                        </div>
                        <div className='col-10 text-end d-none d-lg-block'>
                            <nav className='header_anchor'>
                                <NavLink to='/' style={({ isActive }) => isActive ? activeTab : undefined}>Home</NavLink>
                                <NavLink to="/about" style={({ isActive }) => isActive ? activeTab : undefined}>About</NavLink>
                                {/* <NavLink to='/menu' style={({ isActive }) => isActive ? activeTab : undefined}>Feed</NavLink> */}
                                <NavLink to={homeData?.insta_feed} target='_blank' >Feed</NavLink>
                                <NavLink to='/gallery' style={({ isActive }) => isActive ? activeTab : undefined}>Media Gallery</NavLink>
                                <NavLink to='/events' style={({ isActive }) => isActive ? activeTab : undefined}>Events</NavLink>
                                <NavLink to='/employment' style={({ isActive }) => isActive ? activeTab : undefined}>Employment</NavLink>
                                <NavLink to='/contact' style={({ isActive }) => isActive ? activeTab : undefined}>Contact Us</NavLink>
                            </nav>
                        </div>
                        <div className='col-7 col-sm-8 col-md-9 col-lg-10 text-end d-block d-lg-none'>
                            <HiMenuAlt3 onClick={showDrawer} size={30} color='#fff' />
                        </div>
                    </div>
                </div>
            </header>

            {/* ********For Mobile********* */}
            <Drawer onClose={onClose} open={open}>
                <nav className='mobile_header_anchor'>
                    <Link to='/'>Home</Link>
                    <Link to="/about">About</Link>
                    <NavLink to={homeData?.insta_feed} target='_blank' >Feed</NavLink>
                    <Link to='/gallery'>Media Gallery</Link>
                    <Link to='/events'>Events</Link>
                    <Link to='/employment'>Employment</Link>
                    <Link to='/contact'>Contact Us</Link>
                </nav>
            </Drawer>
        </>
    )
}

export default Header;
