import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    homeData: {},
}

export const authSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        HomeData: (state, action) => {
            state.homeData = action.payload
        },
    }
})

export const { HomeData } = authSlice.actions
export default authSlice.reducer