import React, { useEffect, useState } from 'react';
import { Calendar, Badge } from 'antd';
import { FaLocationDot, FaClock } from "react-icons/fa6";
import { fileUrl, template_id } from '../constants/url';
import { getFetchApi } from '../redux/action';
import { useDispatch } from 'react-redux';
import logo from '../assets/logo.png';
import aboutImg from '../assets/aboutimage.png';
import Footer from '../components/Footer';
import moment from 'moment';
import dayjs from 'dayjs';

const Events = () => {
    const dispatch = useDispatch();
    const currentdate = moment().format("DD MMMM, YYYY")
    const [eventDetails, setEventDetails] = useState(null);
    const [eventData, setEventData] = useState(null);
    const [eventList, setEventList] = useState([]);
    const [formateDate, setFormateDate] = useState(currentdate);

    const getEvenDetails = () => {
        const apiCallBack = (res) => {
            if (res?.status == 200) {
                setEventDetails(res?.data?.data)
            } else {
                console.log(res, "failureeeeeeeeeee")
            }
        }
        let url = `/user/common/template/event_details?template_id=${template_id}`;
        let method = `GET`;
        dispatch(getFetchApi({ url, method, apiCallBack }))
    }

    const getEventList = () => {
        const apiCallBack = (res) => {
            if (res?.status == 200) {
                setEventData(res?.data?.data)
            } else {
                setEventData(null)
            }
        }
        let url = `/user/common/template/event_list?template_id=${template_id}`;
        let method = `GET`;
        dispatch(getFetchApi({ url, method, apiCallBack }))
    }

    useEffect(() => {
        getEvenDetails()
        getEventList()
    }, [])

    useEffect(() => {
        if (eventData) {
            let filteredData = eventData?.result?.filter((i) => {
                const startDate = moment(i.start_date * 1000).format('DD MMMM, YYYY');
                const endDate = moment(i.end_date * 1000).format('DD MMMM, YYYY');
                return startDate === currentdate || endDate === currentdate;
            });
            setEventList(filteredData);
        }
    }, [eventData])

    const handleDateSelect = (value) => {
        let formattedDate = moment(value?.$d).format("DD MMMM, YYYY");
        setFormateDate(formattedDate);
        if (eventData) {
            let filteredData = eventData.result.filter((item) => {
                let startDate = moment(item.start_date * 1000).format('DD MMMM, YYYY');
                let endDate = moment(item.end_date * 1000).format('DD MMMM, YYYY');
                return (startDate === formattedDate || endDate === formattedDate);
            });
            setEventList(filteredData); // Assuming filteredData is an array
        }
    }

    // const dateCellRender = (value) => {
    //     const formattedDate = moment(value?.$d).format('DD MMMM, YYYY');
    //     const eventsForDate = eventData?.result?.filter(item => {
    //         const startDate = moment(item?.start_date * 1000).format('DD MMMM, YYYY');
    //         const endDate = moment(item?.end_date * 1000).format('DD MMMM, YYYY');
    //         return startDate === formattedDate || endDate === formattedDate;
    //     });

    //     // Only render a badge if there are events for the date, and limit it to one badge
    //     const renderBadge = eventsForDate && eventsForDate.length > 0;

    //     return (
    //         <div className='position_dot'>
    //             {renderBadge && <Badge status='error' />}
    //         </div>
    //     );
    // };

    const dateCellRender = (value) => {
        const formattedDate = value.format('DD MMMM, YYYY');
        const hasAppointments = eventData?.result?.some(appointment => moment(appointment?.start_date * 1000).format("DD MMMM, YYYY") === formattedDate || moment(appointment?.end_date * 1000).format("DD MMMM, YYYY") === formattedDate);

        return (
            <div className='position_dot'>
                {hasAppointments && <Badge status="error" />}
            </div>
        );
    };


    return (
        <>
            <main className='home_back' style={{ background: `url(${fileUrl}${eventDetails?.back_image})` }}>
                <div className='common_container'>
                    <div className='about_div mt-0 mt-xxl-5'>
                        <h1 className='title_font'>Events</h1>
                        <h6 className='small_font'>{eventDetails?.description}</h6>
                        <div className='row gy-4 mt-2'>
                            <div className='col-12 col-lg-5'>
                                <Calendar fullscreen={false} onChange={handleDateSelect} cellRender={dateCellRender} className='calendar' />
                            </div>
                            <div className='col-12 col-lg-7 text-start'>
                                <div className='events_detail'>
                                    <h1 className='title_font text-white mb-4' style={{ fontStyle: "italic" }}>{formateDate}</h1>

                                    <div className='row g-3' style={{ maxHeight: "50vh", overflow: "auto" }}>
                                        {eventList?.length > 0 ? eventList?.map((data, indx) => {
                                            const startDate = dayjs(data?.start_date * 1000).format("YYYY-MM-DD");
                                            const endDate = dayjs(data?.end_date * 1000).format("YYYY-MM-DD");
                                            const startDateImg = dayjs(data?.start_date * 1000).format("DD.MM");
                                            const endDateImg = dayjs(data?.end_date * 1000).format("DD.MM");
                                            const yearImg = dayjs(data?.end_date * 1000).format("YYYY");
                                            return (
                                                <>
                                                    <div className='col-sm-3 col-md-2 col-lg-3 col-xxl-2'>
                                                        <div className='img_content' style={{ background: `url(${fileUrl}${data?.event_image})` }}>
                                                            {/* <dt>{startDateImg} - {endDateImg}</dt>
                                                            <dt>{yearImg}</dt>
                                                            <dt>{data?.title}</dt>
                                                            <img src={logo} alt='logo' width='80%' /> */}
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-9 col-md-10 col-lg-9 col-xxl-10'>
                                                        <h5 className='medium_font text-white'>{data?.title}</h5>
                                                        <h6 className='small_font text-white'><FaClock size={20} /> <span className='ps-2'>{startDate} - {endDate}</span></h6>
                                                        <h6 className='small_font text-white'><FaLocationDot size={20} /> <span className='ps-2'>{data?.location}</span></h6>
                                                        <span className='event_btn'>{data?.description}</span>
                                                    </div>
                                                    <hr style={{ borderTop: "1px solid #612c41" }} />
                                                </>
                                            )
                                        })
                                            :
                                            <h3 className='title_font text-White'>No Event Available</h3>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    )
}

export default Events;
