import { MdEmail } from "react-icons/md";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fileUrl } from "../constants/url";
import appStoreImg from '../assets/playApple.png';
import playStoreImg from '../assets/playGoogle.png';
import logo from '../assets/logo.png';

const Footer = () => {
    const homeData = useSelector(state => state?.common?.homeData);
    const openTiming = homeData?.opening_hours;
    const contactData = homeData?.contact_us;

    const formatOpeningHours = (day, timing) => {
        return !timing?.is_closed
            ? `${timing?.open_at || 'N/A'} - ${timing?.close_at || 'N/A'}`
            : 'Closed';
    };

    return (
        <>
            <footer className='footer_div'>
                <div className='common_container'>
                    <div className='row'>
                        <div className='col-sm-6 col-md-4 col-lg-4 py-3 right_border text-center text-sm-start text-md-start text-lg-start'>
                            <h1 className='medium_font under_line'>OPENING HOURS</h1>
                            <div className='my-4 d-flex flex-lg-column'>
                                <table className='table'>
                                    {/* <thead>
                                        <tr>
                                            <th><h6 className="small_font fw-bold">Day</h6></th>
                                            <th><h6 className="small_font fw-bold">Hours</h6></th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <td><h6 className="small_font">Monday</h6></td>
                                            <td><h6 className="small_font">:</h6></td>
                                            <td><h6 className="small_font">{formatOpeningHours('Monday', openTiming?.mon)}</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="small_font">Tuesday</h6></td>
                                            <td><h6 className="small_font">:</h6></td>
                                            <td><h6 className="small_font">{formatOpeningHours('Tuesday', openTiming?.tue)}</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="small_font">Wednesday</h6></td>
                                            <td><h6 className="small_font">:</h6></td>
                                            <td><h6 className="small_font">{formatOpeningHours('Wednesday', openTiming?.wed)}</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="small_font">Thursday</h6></td>
                                            <td><h6 className="small_font me-lg-3">:</h6></td>
                                            <td><h6 className="small_font">{formatOpeningHours('Thursday', openTiming?.thu)}</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="small_font">Friday</h6></td>
                                            <td><h6 className="small_font">:</h6></td>
                                            <td><h6 className="small_font">{formatOpeningHours('Friday', openTiming?.fri)}</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="small_font">Saturday</h6></td>
                                            <td><h6 className="small_font">:</h6></td>
                                            <td><h6 className="small_font">{formatOpeningHours('Saturday', openTiming?.sat)}</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="small_font">Sunday</h6></td>
                                            <td><h6 className="small_font me-lg-3">:</h6></td>
                                            <td><h6 className="small_font">{formatOpeningHours('Sunday', openTiming?.sun)}</h6></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <table className='table'>
                                    <tbody>
                                        <tr>
                                            <td><h6 className="small_font">Friday</h6></td>
                                            <td><h6 className="small_font">:</h6></td>
                                            <td><h6 className="small_font">{formatOpeningHours('Friday', openTiming?.fri)}</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="small_font">Saturday</h6></td>
                                            <td><h6 className="small_font">:</h6></td>
                                            <td><h6 className="small_font">{formatOpeningHours('Saturday', openTiming?.sat)}</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6 className="small_font">Sunday</h6></td>
                                            <td><h6 className="small_font me-3">:</h6></td>
                                            <td><h6 className="small_font">{formatOpeningHours('Sunday', openTiming?.sun)}</h6></td>
                                        </tr>
                                    </tbody>
                                </table> */}
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-4 py-3 right_border d-flex align-items-center justify-content-center'>
                            <img src={`${fileUrl}${homeData?.logo ? homeData?.logo : logo}`} alt='logo_image' width='50%' />
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-4 py-3 d-flex align-items-center justify-content-center justify-content-sm-start justify-content-lg-center justify-content-md-center text-center text-sm-start text-md-start text-lg-start'>
                            <div className=''>
                                <h1 className='medium_font under_line'>CONTACT US</h1>
                                <div className='mt-4'>
                                    <dt className='small_font'>{contactData?.address} {contactData?.apt_suit} {contactData?.city},</dt>
                                    <h6 className='small_font'>{contactData?.state} {contactData?.zipcode}</h6>
                                    <div style={{ borderBottom: "1px dashed #fff", margin: "1rem 0" }}></div>
                                    <h6 className='small_font'><MdEmail size={25} /> {contactData?.email}</h6>
                                </div>
                                <h1 className='medium_font'>Download the Glenwood South App now</h1>
                                <Link to="https://apps.apple.com/in/app/glenwood-south/id6479742734" target="_blank"><img src={appStoreImg} width='25%' /></Link>
                                <Link to="https://play.google.com/store/apps/details?id=com.glenwood" target="_blank"><img src={playStoreImg} width='25%' className="ms-2" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
