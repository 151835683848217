import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Loader from '../components/Loader';
import EventsComponents from '../pages/Events';

const Home = lazy(() => import('../pages/Home'));
const About = lazy(() => import('../pages/About'));
const Menu = lazy(() => import('../pages/Menu'));
const Gallery = lazy(() => import('../pages/Gallery'));
const Contact = lazy(() => import('../pages/Contact'));
const Employment = lazy(() => import('../pages/Employment'));

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const Routing = () => {
    return (
        <>
            <Suspense fallback={<Loader />}>
                <BrowserRouter>
                    <ScrollToTop />
                    <Routes>
                        <Route element={<ProtectedRoute />}>
                            <Route path='/' element={<Home />} />
                            <Route path='/about' element={<About />} />
                            <Route path='/menu' element={<Menu />} />
                            <Route path='/gallery' element={<Gallery />} />
                            <Route path='/events' element={<EventsComponents />} />
                            <Route path='/contact' element={<Contact />} />
                            <Route path='/employment' element={<Employment />} />
                            <Route path='*' element={<h1 className='text-center'>404 NOT FOUND</h1>} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </>
    )
}

export default Routing;
