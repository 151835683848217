import { Toaster } from 'react-hot-toast';
import Routing from './routing/Routing';

const App = () => {
  return (
    <>
      <Routing />
      <Toaster position='top-right'
        reverseOrder={true}
        toastOptions={{
          success: {
            style: {
              borderRadius: '50px',
              background: '#333',
              color: '#fff',
            },
          },
          error: {
            style: {
              borderRadius: '50px',
              background: '#333',
              color: '#fff',
            },
          },
        }} />
    </>
  )
}

export default App;
